import { siteConfig } from "../config";

/**
 * Source Protection Utility
 * 
 * This utility automatically hides source files from being viewed by users
 * by removing them from the DOM and preventing inspection.
 * Now includes IP-based allowlist for development.
 */

export const initializeSourceProtection = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  window.addEventListener('load', function() {
    setTimeout(() => {
      document.body.classList.add('animations-ready');
      void(document.body.offsetHeight);
    }, 100);
  });

  const allowedIPs = [
    siteConfig.ips.home,
  ];
  
  const checkIPAllowlist = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const currentIP = data.ip;
      
      if (allowedIPs.includes(currentIP)) {
        console.log('Developer access granted');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error checking IP', error);
      return false;
    }
  };

  const sourcePatterns = [
    /\.jsx$/i, /\.tsx$/i, /\.ts$/i, /\.map$/i,
    
    /node_modules\/react-dev/, /node_modules\/webpack-dev/,
    
    /\/src\/components\//, /\/src\/pages\//, /\/src\/utils\//,
    /\/src\/api\//, /\/src\/services\//, /\/src\/hooks\//, /\/src\/config\//,
    /\/src\/security\//, /\/storption\/src\//,
    
    /\/config\/webpack/, /\.env\.development/,
    
    /\/server\/middleware\//, /\/server\/routes\//, /\/server\/controllers\//,
    /\/server\/models\//, /\/server\/utils\//, /\/server\/helpers\//
  ];
  
  const safePatterns = [
    /\.css$/i,
    /static\/css/,
    /static\/js/,
    /static\/media/,
    /main\.[\w\d]+\.js/,
    /chunk\.[\w\d]+\.js/,
    /runtime-main\.[\w\d]+\.js/,
    /favicon/,
    /manifest/,
    /logo/,
    /icon/,
    /\.svg$/,
    /\.png$/,
    /\.jpg$/,
    /\.jpeg$/,
    /\.gif$/,
    /\.webp$/,
    /\.woff/,
    /\.woff2/,
    /\.ttf/,
    /\.eot/
  ];
  
  const shouldProtect = (path) => {
    if (!path || typeof path !== 'string') return false;
    
    for (const pattern of safePatterns) {
      if (pattern.test(path)) {
        return false;
      }
    }
    
    return sourcePatterns.some(pattern => pattern.test(path));
  };
  
  const applyProtections = () => {
    const style = document.createElement('style');
    style.id = "source-protection-styles";
    
    const styleRules = [];
    
    for (const pattern of sourcePatterns) {
      const patternStr = pattern.source.replace(/\//g, '/');
      styleRules.push(`
        [src*="${patternStr}"],
        [href*="${patternStr}"],
        script[src*="${patternStr}"] {
          display: none !important;
          visibility: hidden !important;
          opacity: 0 !important;
          position: absolute !important;
          width: 0 !important;
          height: 0 !important;
          overflow: hidden !important;
        }
      `);
    }
    
    style.textContent = styleRules.join('\n');
    document.head.appendChild(style);
    
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === 1) {
            const element = node;
            
            if (element.tagName === 'LINK' && element.getAttribute('rel') === 'stylesheet') {
              return;
            }
            
            const attributes = ['src', 'href', 'id', 'class'];
            for (const attr of attributes) {
              const value = element.getAttribute(attr);
              if (value && shouldProtect(value)) {
                element.remove();
              }
            }
            
            if (element.tagName === 'SCRIPT' && element.textContent) {
              if (/function|const|var|let|import|export|class/.test(element.textContent)) {
                element.textContent = `console.log("Script executed");`;
              }
            }
          }
        });
      });
    });
    
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
      attributes: true
    });
  
    document.addEventListener('contextmenu', (e) => e.preventDefault());
    
    document.addEventListener('keydown', (e) => {
      if ((e.ctrlKey && (e.key === 'u' || e.key === 's')) || e.key === 'F12') {
        e.preventDefault();
        return false;
      }
    });
    
    const detectDevTools = () => {
      const widthThreshold = window.outerWidth - window.innerWidth > 160;
      const heightThreshold = window.outerHeight - window.innerHeight > 160;
      
      if (widthThreshold || heightThreshold) {
        console.clear();
        document.body.innerHTML = `
          <div style="position:fixed;top:0;left:0;width:100%;height:100%;background:#111;color:#fff;display:flex;align-items:center;justify-content:center;z-index:99999">
            <div style="text-align:center;padding:20px;">
              <h1 style="margin-bottom:20px;">⚠️ Security Alert</h1>
              <p>Developer tools are not allowed on this page.</p>
              <button onclick="location.reload()" style="margin-top:20px;padding:10px 20px;background:#4338ca;border:none;color:#fff;border-radius:5px;cursor:pointer">
                Reload Page
              </button>
            </div>
          </div>
        `;
      }
    };
    
    const devToolsDetectionInterval = setInterval(detectDevTools, 1000);
    
    window._devToolsDetectionInterval = devToolsDetectionInterval;
    
    const originalConsoleLog = console.log;
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;
    const originalConsoleInfo = console.info;
    
    console.log = function() {
      if (process.env.NODE_ENV === 'production') return;
      return originalConsoleLog.apply(console, arguments);
    };
    
    console.error = function() {
      if (process.env.NODE_ENV === 'production') {
        return originalConsoleError.call(console, 'An error occurred');
      }
      return originalConsoleError.apply(console, arguments);
    };
    
    console.warn = function() {
      if (process.env.NODE_ENV === 'production') return;
      return originalConsoleWarn.apply(console, arguments);
    };
    
    console.info = function() {
      if (process.env.NODE_ENV === 'production') return;
      return originalConsoleInfo.apply(console, arguments);
    };
    
    setInterval(() => {
      const elements = document.querySelectorAll('*');
      elements.forEach(element => {
        const src = element.getAttribute('src');
        const href = element.getAttribute('href');
        
        if ((src && shouldProtect(src)) || (href && shouldProtect(href))) {
          element.remove();
        }
      });
      
      try {
        const keys = Object.keys(localStorage);
        for (const key of keys) {
          if (sourcePatterns.some(pattern => pattern.test(key))) {
            localStorage.removeItem(key);
          }
        }
      } catch (e) {}
    }, 2000);
    
    Object.defineProperty(window, 'console', {
      get: function() {
        if (process.env.NODE_ENV === 'production') {
          return {
            log: function() {},
            warn: function() {},
            error: function() {},
            info: function() {},
            debug: function() {},
            table: function() {},
            trace: function() {},
            dir: function() {},
            dirxml: function() {},
            group: function() {},
            groupCollapsed: function() {},
            groupEnd: function() {},
            clear: function() {},
            count: function() {},
            countReset: function() {},
            assert: function() {},
            profile: function() {},
            profileEnd: function() {},
            time: function() {},
            timeLog: function() {},
            timeEnd: function() {},
            timeStamp: function() {}
          };
        }
        return {
          log: originalConsoleLog,
          error: originalConsoleError,
          warn: originalConsoleWarn,
          info: originalConsoleInfo
        };
      }
    });
  };
  
  (async () => {
    const isAllowedIP = await checkIPAllowlist();
    
    if (!isAllowedIP) {
      applyProtections();
    } else {
      const devIndicator = document.createElement('div');
      devIndicator.style.cssText = `
        position: fixed;
        bottom: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.7);
        color: #00ff00;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 4px;
        z-index: 9999;
        pointer-events: none;
      `;
      devIndicator.textContent = 'Dev Mode';
      document.body.appendChild(devIndicator);
    }
  })();
};

export default initializeSourceProtection;