import React, { useState, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Bot } from 'lucide-react';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction } from './components/ui/alert-dialog';
import Navigation from './components/Navigation';
import ErrorBoundary from './components/ErrorBoundary';
import { siteConfig } from './config';
import { initializeSourceProtection } from './security/sourceProtection';
import API_ENDPOINTS from './apiConfig';
import AdminRoute from './components/AdminRoute';

// Lazy load pages for better performance
const Home = lazy(() => import('./pages/Home'));
const Contact = lazy(() => import('./pages/Contact'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const Success = lazy(() => import('./pages/Success'));
const Insights = lazy(() => import('./pages/Insights'));
const About = lazy(() => import('./pages/About'));
const AuthCallback = lazy(() => import('./pages/AuthCallback'));
const LoginError = lazy(() => import('./pages/LoginError'));
const Profile = lazy(() => import('./pages/Profile'));
const FAQPage = lazy(() => import('./pages/FAQPage'));
const PreviewPage = lazy(() => import('./pages/Preview'));
const Directory = lazy(() => import('./pages/Directory'));
const DashboardWrapper = lazy(() => import('./pages/DashboardWrapper'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Admin Pages
const Admin = lazy(() => import('./pages/admin/Admin'));
const Logs = lazy(() => import('./pages/admin/Logs'));

const Loading = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const simulateLoading = () => {
      setProgress(prev => {
        if (prev < 20) return prev + 5;
        if (prev < 50) return prev + 2;
        if (prev < 80) return prev + 0.8;
        if (prev < 95) return prev + 0.3;
        return prev;
      });
    };

    const interval = setInterval(simulateLoading, 100);
    
    const finalTimer = setTimeout(() => {
      setProgress(100);
    }, 1400);
    
    return () => {
      clearInterval(interval);
      clearTimeout(finalTimer);
    };
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 interactive-bg">
      <div className="relative">
        {/* Ambient glow effect */}
        <div className="absolute -inset-8 bg-indigo-500/20 rounded-full blur-xl animate-pulse"></div>
        
        <div className="relative glass-card p-8 rounded-xl border border-indigo-500/30 backdrop-blur-md max-w-sm w-full">
          <motion.div
            animate={{
              y: [0, -10, 0],
              rotateZ: [0, 5, -5, 0],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
            className="flex justify-center mb-6"
          >
            <Bot className="w-16 h-16 text-indigo-400" />
          </motion.div>
          
          <div className="space-y-6">
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-white text-xl font-medium text-center gradient-text"
            >
              Loading amazing features...
            </motion.p>
            
            <div className="relative">
              {/* Progress bar container */}
              <div className="h-3 bg-gray-800/70 rounded-full overflow-hidden backdrop-blur-sm">
                <motion.div 
                  initial={{ width: 0 }}
                  animate={{ width: `${progress}%` }}
                  transition={{ ease: "easeOut" }}
                  className="h-full bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 bg-size-200 animate-gradient-x"
                />
              </div>
              
              {/* Progress percentage */}
              <p className="text-indigo-300 text-sm mt-2 text-center">
                {Math.round(progress)}%
              </p>
              
              {/* Particles effect */}
              {[...Array(5)].map((_, i) => (
                <motion.div
                  key={i}
                  className="absolute w-1 h-1 bg-indigo-400 rounded-full"
                  style={{
                    left: `${progress}%`,
                    top: '50%',
                    '--x': `${(Math.random() * 40) - 20}px`,
                    '--y': `${(Math.random() * 40) - 20}px`,
                  }}
                  animate={{
                    opacity: [0, 1, 0],
                    scale: [0, 1, 0],
                    x: `var(--x)`,
                    y: `var(--y)`,
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    delay: i * 0.2,
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [currency, setCurrency] = useState('GBP');
  const [showContact, setShowContact] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    initializeSourceProtection();
    document.body.classList.add('has-animations');
    
    const initializeApp = async () => {
      const loadingTimer = setTimeout(() => setIsLoading(false), 1500);

      document.body.style.opacity = '1';
      
      const consentGiven = localStorage.getItem('cookieConsent');
      if (consentGiven) setShowCookieConsent(false);
      
      try {
          const response = await fetch(API_ENDPOINTS.AUTH.ME, {
            credentials: 'include',
          });
          
          if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              const data = await response.json();
              setUser(data.user);
            } else {
              console.error('Unexpected content type from auth endpoint:', contentType);
              localStorage.removeItem('user');
            }
        }
      } catch (error) {
        console.error('Error initializing app:', error);
        localStorage.removeItem('user');
      }
      
      const savedCurrency = localStorage.getItem('preferredCurrency');
      if (savedCurrency && (savedCurrency === 'GBP' || savedCurrency === 'USD')) {
        setCurrency(savedCurrency);
      }
      
      return () => clearTimeout(loadingTimer);
    };
    
    initializeApp();

    return () => {
      document.body.classList.remove('has-animations');
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('preferredCurrency', currency);
  }, [currency]);

  const handleCookieConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowCookieConsent(false);
  };

  if (isLoading) return <Loading />; 

  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-gray-900">
          <Navigation 
            currency={currency} 
            setCurrency={setCurrency}
            setShowContact={setShowContact}
            user={user}
            setUser={setUser}
          />

          {/* Main Content */}
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home currency={currency} setCurrency={setCurrency} />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/checkout" element={<CheckoutPage currency={currency} setCurrency={setCurrency} />} />
              <Route path="/success" element={<Success />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/about" element={<About />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/login-error" element={<LoginError />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/directory" element={<Directory />} />
              <Route path="/preview" element={<PreviewPage />} />
              <Route path="/dashboard" element={<DashboardWrapper />} />
              <Route path="*" element={<NotFound />} />

              {/* Admin Pages*/}
              <Route path="/admin" element={
                <AdminRoute>
                  <Admin />
                </AdminRoute>
              } />
              <Route path="/admin/logs" element={
                <AdminRoute>
                  <Logs />
                </AdminRoute>
              } />
            </Routes>
          </Suspense>

          {/* Contact Dialog */}
          <AlertDialog open={showContact} onOpenChange={setShowContact}>
            <AlertDialogContent className="bg-gray-900 border border-gray-800">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-2xl text-white">Contact Me</AlertDialogTitle>
                <AlertDialogDescription className="text-gray-400">
                  <div className="space-y-4">
                    <p>Contact me through any of these:</p>
                    <ul className="space-y-2">
                      <li className="flex items-center space-x-2">
                        <span>Discord:</span>
                        <span className="text-indigo-400">{siteConfig.contact.discord}</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span>Email:</span>
                        <span className="text-indigo-400">{siteConfig.contact.email}</span>
                      </li>
                    </ul>
                  </div>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogAction 
                  className="bg-indigo-600 hover:bg-indigo-700 text-white"
                  onClick={() => setShowContact(false)}
                >
                  Got it, thanks!
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          {/* Cookie Consent Banner */}
          {showCookieConsent && (
            <div className="fixed bottom-0 w-full bg-gray-800 text-white py-4 px-6 flex flex-col md:flex-row items-center justify-between z-50">
              <p className="text-sm text-center md:text-left mb-4 md:mb-0">
                We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
              </p>
              <button 
                onClick={handleCookieConsent}
                className="px-4 py-2 bg-indigo-600 rounded-md hover:bg-indigo-700 transition-colors duration-300"
              >
                Accept
              </button>
            </div>
          )}
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;