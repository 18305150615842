import React, { Component } from 'react';
import { AlertCircle, RefreshCw, ArrowLeft, Wifi, Database, Server } from 'lucide-react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null,
      errorType: 'general' // Can be: 'general', 'network', 'api', 'data', 'auth'
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let errorType = 'general';
    
    if (error.message && typeof error.message === 'string') {
      if (error.message.includes('network') || error.message.includes('fetch') || error.message.includes('Failed to fetch')) {
        errorType = 'network';
      } else if (error.message.includes('API') || error.message.includes('api') || error.message.includes('endpoint')) {
        errorType = 'api';
      } else if (error.message.includes('data') || error.message.includes('undefined property') || error.message.includes('Cannot read property')) {
        errorType = 'data';
      } else if (error.message.includes('auth') || error.message.includes('token') || error.message.includes('permission')) {
        errorType = 'auth';
      }
    }

    // Log the error to console (you could also send to your error reporting service)
    console.error('Error caught by boundary:', error, errorInfo);
    console.error('Error type:', errorType);
    
    this.setState({
      error: error,
      errorInfo: errorInfo,
      errorType: errorType
    });

    // You could also send this to a reporting service
    this.reportErrorToService(error, errorInfo, errorType);
  }

  reportErrorToService(error, errorInfo, errorType) {
    // In a production environment, you would send this to your error reporting service
    // Example with Sentry (if implemented):
    // if (window.Sentry) {
    //   window.Sentry.captureException(error, {
    //     extra: {
    //       componentStack: errorInfo.componentStack,
    //       errorType
    //     }
    //   });
    // }
    
    // For now, just log to console in a structured way
    console.group('Error Report');
    console.error('Error:', error.toString());
    console.error('Type:', errorType);
    console.error('Component Stack:', errorInfo.componentStack);
    console.error('User Agent:', navigator.userAgent);
    console.error('Timestamp:', new Date().toISOString());
    console.groupEnd();
  }

  handleRefresh = () => {
    window.location.reload();
  };

  handleGoHome = () => {
    window.location.href = '/';
  };

  renderErrorContent() {
    const { errorType } = this.state;

    switch (errorType) {
      case 'network':
        return {
          icon: <Wifi className="w-16 h-16 text-red-500" />,
          title: "Network Error",
          message: "We couldn't connect to our servers. Please check your internet connection and try again.",
          action: "Refresh the page"
        };
      
      case 'api':
        return {
          icon: <Server className="w-16 h-16 text-orange-500" />,
          title: "Service Unavailable",
          message: "My service is currently experiencing issues. I has been notified and am working on a fix.",
          action: "Try again later"
        };
      
      case 'data':
        return {
          icon: <Database className="w-16 h-16 text-yellow-500" />,
          title: "Data Error",
          message: "We encountered an issue processing some data. This may be temporary - please try refreshing.",
          action: "Refresh the page"
        };
      
      case 'auth':
        return {
          icon: <AlertCircle className="w-16 h-16 text-purple-500" />,
          title: "Authentication Error",
          message: "There was a problem with your session. Please try logging in again.",
          action: "Return to home"
        };
      
      default:
        return {
          icon: <AlertCircle className="w-16 h-16 text-red-500" />,
          title: "Something Went Wrong",
          message: "We encountered an unexpected error. Please try refreshing the page or return home.",
          action: "Refresh the page"
        };
    }
  }

  render() {
    if (this.state.hasError) {
      const errorContent = this.renderErrorContent();

      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 p-4">
          <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 max-w-md w-full text-center">
            <div className="flex justify-center mb-6">
              <div className="relative">
                <div className="absolute -inset-1 bg-red-500/30 rounded-full blur-md animate-pulse"></div>
                {errorContent.icon}
              </div>
            </div>
            
            <h1 className="text-2xl font-bold text-white mb-4">{errorContent.title}</h1>
            
            <div className="text-gray-400 mb-6">
              <p className="mb-2">{errorContent.message}</p>
            </div>
            
            <div className="mb-6 p-6 bg-gray-700/50 rounded-lg text-left overflow-auto max-h-64 border border-gray-600/50">
              <p className="text-red-400 font-mono text-sm mb-4 break-words">
                {this.state.error && this.state.error.toString()}
              </p>
              <div className="text-gray-400 font-mono text-xs break-words">
                <p className="mb-2 text-indigo-300 font-semibold">Component Stack:</p>
                <pre className="whitespace-pre-wrap">
                  {this.state.errorInfo && this.state.errorInfo.componentStack}
                </pre>
              </div>
            </div>
            
            <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
              <button
                onClick={this.handleRefresh}
                className="flex items-center justify-center px-4 py-2 bg-indigo-600 hover:bg-indigo-700 rounded-lg text-white font-medium"
              >
                <RefreshCw className="w-5 h-5 mr-2" />
                Refresh Page
              </button>
              
              <button
                onClick={this.handleGoHome}
                className="flex items-center justify-center px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-white font-medium"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Go Home
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;