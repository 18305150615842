import { Shield, Zap, Code } from 'lucide-react';

export const siteConfig = {
  ips: {
    home: "80.192.21.104"
  },


  //#region Neon Text Color
  theme: {
    neon: {
      primary: "rgb(131, 142, 235)",
      shadow: "rgba(131, 142, 235, 0.5)"
    }
  },
  //#endregion

  //#region Site General
  siteName: "Storption",
  heroTitle: "Next-Gen Discord Bots",
  heroDescription: "Transform your Discord server with custom-built bots featuring cutting-edge technology",
  //#endregion

  //#region Currencies
  currencies: {
    GBP: {
      symbol: '£'
    },
    USD: {
      symbol: '$'
    }
  },
  //#endregion

  //#region Menu Items
  menuItems: [],
  //#endregion

  //#region Home Features
  features: [
    {
      icon: Shield,
      title: 'Advanced Security',
      description: 'Protect your server with state-of-the-art moderation tools'
    },
    {
      icon: Zap,
      title: 'Lightning Fast',
      description: 'Optimized performance with rapid response times'
    },
    {
      icon: Code,
      title: 'Custom Development',
      description: 'Tailor-made solutions for your unique needs'
    }
  ],
  //#endregion

  //#region Pricing
  pricing: {
    title: "Simple Pricing",
    description: "The perfect plan for your needs",
      setupFees: {
        basic: {
          GBP: {
            amount: "34.50",
            stripe_price_id: "price_1QUehYFHTsXmpA5pCPp1goBF"
          },
          USD: {
            amount: "44.00",
            stripe_price_id: "price_1QUehvFHTsXmpA5p1D0xExrH"
          }
        },
        supporter: {
          GBP: {
            amount: "34.50",
            stripe_price_id: "price_1QUehYFHTsXmpA5pCPp1goBF"
          },
          USD: {
            amount: "44.00",
            stripe_price_id: "price_1QUehvFHTsXmpA5p1D0xExrH"
          }
        }
      },
    plans: [
      {
        name: "My Service",
        prices: {
          GBP: {
            monthly: "5.50",
            yearly: "66.00"
          },
          USD: {
            monthly: "7.00",
            yearly: "86.00"
          }
        },
        stripe_price_ids: {
          GBP: {
            monthly: "price_1QUeGuFHTsXmpA5pwgQt9R69",
            yearly: "price_1QUeHkFHTsXmpA5psksZaI8V"
          },
          USD: {
            monthly: "price_1QUeI9FHTsXmpA5pgLT786on",
            yearly: "price_1QUeISFHTsXmpA5pzpBxsMNx"
          }
        },
        features: [
      "Fully customized Discord bot tailored to your exact requirements",
      "Advanced feature implementation (moderation, tickets, analytics, literally anything you want.)",
      "24/7 uptime monitoring and maintenance",
      "Continuous updates and bug fixes",
      "Regular performance optimization",
      "Custom commands and automation",        ],
        buttonText: "Get Started",
        gradient: "from-indigo-500 via-purple-500 to-pink-500",
        buttonClass: "bg-indigo-600 hover:bg-indigo-700"
      },
      {
        name: "Supporter Service",
        prices: {
          GBP: {
            monthly: "15.00",
            yearly: "180.00"
          },
          USD: {
            monthly: "19.00",
            yearly: "229.00"
          }
        },
        stripe_price_ids: {
          GBP: {
            monthly: "price_1QUeKCFHTsXmpA5pVd9FI8CL",
            yearly: "price_1QUeKeFHTsXmpA5pR4XGhPHi"
          },
          USD: {
            monthly: "price_1QUeL0FHTsXmpA5pNOo0r7Wx",
            yearly: "price_1QUeLWFHTsXmpA5p23BFDq4d"
          }
        },
        features: [
          "All features from Basic Service",
          "Priority development queue position",
          "VIP support with faster response times",
          "Priority bug fixes and maintenance",
          "Your support helps fuel the development of innovative Discord bot solutions"
        ],
        buttonText: "Be Extra Supportive",
        gradient: "from-purple-500 via-pink-500 to-indigo-500",
        buttonClass: "bg-purple-600 hover:bg-purple-700"
      }
    ]
  },
  //#endregion

  //#region FAQ Section
  faq: {
    title: "Frequently Asked Questions",
    description: "Find answers to common questions about our services",
    questions: [
      {
        question: "How long does it take to set up a custom bot?",
        answer: "Most custom bots can be set up within 24-48 hours. Complex implementations may take longer."
      },
      {
        question: "Why is there a setup fee?",
        answer: "The setup fee covers the initial development of your custom bot, including all the feature implementation and testing. This is a one-time payment to get your bot up and running. Check out the Benefits section for more details on what's included!"
      },
      {
        question: "Whats the point for a supporter service?",
        answer: "The Supporter Service is plainly there just to heavily support me Financially and all you'd mainly be getting is high priority since that service is mainly just if you'd like to really help support me and so whoever decides to use that service thank you very very much"
      }
    ]
  },
  //#endregion

  //#region CTA Section
  cta: {
    title: "Ready to Transform Your Server?",
    description: "Be apart of satisfied server owners who elevate their Discord communities with my custom bots.",
    buttonText: "Get Started Now"
  },
  //#endregion

  //#region Contact Info
  contact: {
    discord: "Storption",
    email: "storption.business@gmail.com"
  },
  //#endregion

  insights: {
    //#region Insights Hero
    hero: {
      title: "Discover the Power of Discord Bots",
      description: "Unlock the full potential of your Discord server with custom bot development",
      buttonText1: "Explore Features",
      buttonText2: "See Examples"
    },
    //#endregion
    
    //#region Insights Explanations
    explanations: {
      title: "Transform Your Server",
      subtitle: "Discover what's possible with custom Discord bots",
      sections: [
        {
          title: "Moderation & Security",
          icon: "Shield",
          description: "Keep your server safe with advanced moderation tools",
          examples: [
            "Auto-moderation of content",
            "Customizable anti-spam filters",
            "Raid protection systems",
            "User verification systems"
          ]
        },
        {
          title: "Community Management",
          icon: "Users",
          description: "Foster an active and engaged community",
          examples: [
            "Custom welcome messages",
            "Role management systems",
            "Community events automation",
            "Interactive features"
          ]
        },
        {
          title: "Support Systems",
          icon: "MessageCircle",
          description: "Streamline support for your community",
          examples: [
            "Ticket management system",
            "FAQ automation",
            "Custom help commands",
            "User guide integration"
          ]
        }
      ]
    },
    //#endregion

    //#region Insights Comparison
    comparison: {
      title: "Why Choose Custom?",
      subtitle: "Compare custom bots with public alternatives",
      categories: [
        {
          title: "Features & Customization",
          custom: [
            "Fully customized to your needs",
            "Unlimited feature potential",
            "Server-specific solutions"
          ],
          public: [
            "Limited pre-built features",
            "Generic functionality",
            "One-size-fits-all approach"
          ]
        },
        {
          title: "Support & Maintenance",
          custom: [
            "24/7 uptime monitoring",
            "Priority support",
            "Regular updates & maintenance"
          ],
          public: [
            "Basic support",
            "Shared resources",
            "Delayed updates"
          ]
        }
      ]
    },
    //#endregion

    //#region Insights Overview
    overview: {
      title: "Understanding Discord Bots",
      subtitle: "New to Discord bots? Let's break it down!",
      sections: [
        {
          title: "What is a Discord Bot?",
          icon: "Bot",
          description: "Think of a Discord bot as your server's personal assistant - available 24/7 to help manage, moderate, and enhance your community. It's like having an extra staff member that never sleeps!",
          points: [
            "Works automatically without needing constant supervision",
            "Responds instantly to commands and server events",
            "Handles multiple tasks simultaneously",
            "Customizable to fit your exact needs"
          ]
        },
        {
          title: "What Can They Do?",
          icon: "Zap",
          description: "From basic moderation to complex custom features, bots can transform how your server operates:",
          examples: [
            "Automatically welcome new members with custom messages",
            "Create support tickets for member assistance",
            "Manage roles and permissions automatically",
            "Generate custom commands for your server",
            "Create interactive experiences for your community"
          ]
        },
        {
          title: "Why Do You Need One?",
          icon: "Star",
          description: "As your Discord server grows, manual management becomes increasingly challenging. A custom bot helps you:",
          benefits: [
            "Save countless hours on repetitive tasks",
            "Provide better service to your members",
            "Maintain consistent moderation",
            "Create unique features for your community"
          ]
        }
      ]
    },
    //#endregion
  
    //#region Insights Benefits
    benefits: {
      title: "Benefits of Custom Bots",
      subtitle: "Discover why custom bots are the superior choice",
      sections: [
        {
          title: "Freedom to Create",
          description: "Unlike public bots with preset features, custom bots can be built exactly how you want them:",
          points: [
            "Design features specifically for your community",
            "Create unique commands and functionality",
            "Implement your own ideas without limitations",
            "Scale and adapt as your server grows"
          ]
        },
        {
          title: "Professional Quality",
          description: "Stand out from other servers with premium features:",
          points: [
            "Custom-branded welcome messages and interactions",
            "Professional ticketing and support systems",
            "Advanced moderation tools tailored to your rules",
            "Unique community engagement features"
          ]
        }
      ]
    },
    //#endregion
  
    //#region Insights Why Me
    whyMe: {
      title: "Why Choose Storption?",
      subtitle: "Get the perfect bot for your community",
      points: [
        {
          title: "Personal Attention",
          description: "Direct communication with your developer - no middlemen, no automated responses, just real person-to-person service.",
          examples: [
            "Direct access to the developer",
            "Quick response times",
            "Clear communication throughout development",
            "Ongoing support and maintenance"
          ]
        },
        {
          title: "Custom Development",
          description: "Every bot is built from scratch to match your exact requirements - no templates, no limitations.",
          examples: [
            "Fully customized features",
            "Unique functionality for your server",
            "Regular updates and improvements",
            "Scalable solutions that grow with you"
          ]
        },
        {
          title: "Quality Assurance",
          description: "Your bot will be thoroughly tested and optimized before deployment.",
          examples: [
            "Comprehensive testing",
            "Performance optimization",
            "99.9% uptime guarantee",
            "Regular maintenance and updates"
          ]
        }
      ]
    }
    //#endregion
  },

  about: {
    //#region About Hero
    hero: {
      title: "Get to Know Me",
      description: "A passionate developer dedicated to creating amazing Discord bot experiences"
    },
    //#endregion

    //#region About Journey
    journey: {
      title: "My Development Journey",
      subtitle: "From curious beginner to dedicated Discord bot developer",
      content: [
        {
          text: "My journey into development began around two years ago when I was looking for a new challenge. Drawn to programming, I chose Discord bot development as my starting point since it was something I was already familiar with as a user."
        },
        {
          text: "Starting with YouTube tutorials by ",
          link: {
            text: "UnderCTRL",
            url: "https://www.youtube.com/@UnderCtrl"
          },
          textAfter: ", Which major shoutout to him, I learned the basics of Discord bot development. This foundation sparked my interest to dive deeper, leading me to explore Discord's documentation and expand my knowledge independently."
        }
      ]
    },
    //#endregion

    //#region About Motivation
    motivation: {
      title: "What Drives Me",
      subtitle: "My passion for programming and helping communities thrive",
      points: [
        {
          icon: "Heart",
          title: "Passion for Programming",
          description: "I find programming incredibly fun, interesting, and helpful in many ways. It's not just a skill - it's a creative outlet that allows me to build solutions that make a difference."
        },
        {
          icon: "Star",
          title: "Community Focus",
          description: "My goal is to help communities transform their Discord servers into vibrant, interactive spaces that go beyond just chat platforms."
        },
        {
          icon: "Target",
          title: "Future Vision",
          description: "I aim to continue growing as a developer while building an online business focused on creating exceptional community tools and bot solutions."
        }
      ]
    },
    //#endregion

    //#region About Experience
    experience: {
      title: "Bot Development Experience",
      subtitle: "Diverse experience in creating various types of Discord bots",
      botTypes: [
        {
          icon: "Coffee",
          title: "Moderation Bots",
          description: "Advanced moderation tools to keep communities safe and well-managed"
        },
        {
          icon: "Bot",
          title: "Support & Ticket Bots",
          description: "Streamlined systems for handling community support and inquiries"
        },
        {
          icon: "Rocket",
          title: "Game Integration Bots",
          description: "Interactive bots that enhance gaming communities"
        },
        {
          icon: "Code",
          title: "Custom Feature Bots",
          description: "Specialized bots with unique features tailored to specific community needs"
        }
      ],
      philosophy: "I believe in creating bots that don't just fulfill a function, but enhance the entire community experience. Every bot is an opportunity to create something unique that perfectly matches the community's needs."
    },
    //#endregion

    //#region About CTA
    cta: {
      title: "Ready to Create Something Amazing?",
      description: "Let's work together to build the perfect bot for your Discord community."
    },
    //#endregion

    //#region About Tech Stack
    techStack: {
      title: "Tech Stack",
      subtitle: "The technologies I use to create powerful Discord bots",
      technologies: [
        {
          icon: "Code",
          name: "JavaScript/Node.js",
          description: "Primary programming language and runtime environment"
        },
        {
          icon: "Bot",
          name: "Discord.js",
          description: "Powerful Node.js module for interacting with Discord's API"
        },
        {
          icon: "Database",
          name: "MongoDB",
          description: "Database solution for storing bot and server data"
        },
        {
          icon: "Code",
          name: "Visual Studio Code",
          description: "My preferred code editor that makes development a breeze"
        }
      ]
    }
    //#endregion
  },

  //#region Pages No Back Button
  pagesWithoutBackButton: [
    '/directory',
    '/contact',
    '/faq',

    '/admin/logs'
  ]
  //#endregion
};