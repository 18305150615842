import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LogOut, User, Code, ChevronDown, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import API_ENDPOINTS from '../apiConfig';

const UserMenu = ({ user, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.AUTH.LOGOUT, {
        method: 'POST',
        credentials: 'include'
      });
      if (response.ok) {
        localStorage.removeItem('user');
        onLogout();
        window.location.reload();
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const getDiscordAvatar = (userId, avatarId) => {
    return avatarId 
      ? `https://cdn.discordapp.com/avatars/${userId}/${avatarId}.png` 
      : 'https://cdn.discordapp.com/embed/avatars/0.png';
  };

  return (
    <div className="relative">
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 p-1 rounded-lg hover:bg-gray-800 transition-colors"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <div className="relative">
          <img
            src={getDiscordAvatar(user.discordId, user.avatar)}
            alt={user.username}
            className="w-8 h-8 rounded-full ring-2 ring-indigo-500"
          />
          <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-green-500 rounded-full border-2 border-gray-900" />
        </div>
        <span className="text-white font-medium hidden md:block">{user.username}</span>
        <ChevronDown 
          className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 z-40"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
            />
            
            <motion.div
              className="absolute right-0 mt-2 w-64 rounded-lg bg-gray-800 shadow-lg ring-1 ring-gray-700 z-50 overflow-hidden"
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -10, scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <div className="p-4 border-b border-gray-700 bg-gradient-to-r from-gray-800 to-gray-700">
                <div className="flex items-center space-x-3">
                  <img
                    src={getDiscordAvatar(user.discordId, user.avatar)}
                    alt={user.username}
                    className="w-12 h-12 rounded-full ring-2 ring-indigo-500"
                  />
                  <div>
                    <p className="text-white font-medium">{user.username}</p>
                    {user.admin && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-indigo-600 text-white">
                        <Shield className="w-3 h-3 mr-1" />
                        Admin
                      </span>
                    )}
                  </div>
                </div>
                
                {user.customerHistory?.hasBeenCustomer && (
                  <div className="mt-2 pt-2 border-t border-gray-700/50">
                    <p className="text-xs text-indigo-400">
                      {user.currentSubscription?.isActive ? (
                        <span className="flex items-center">
                          <span className="w-2 h-2 bg-green-500 rounded-full mr-1"></span>
                          Active subscriber
                        </span>
                      ) : (
                        <span className="flex items-center">
                          <span className="w-2 h-2 bg-gray-500 rounded-full mr-1"></span>
                          Previous customer
                        </span>
                      )}
                    </p>
                  </div>
                )}
              </div>
              
              <div className="p-2 space-y-1">
                <motion.button
                  whileHover={{ x: 5, backgroundColor: 'rgba(75, 85, 99, 0.5)' }}
                  className="w-full px-3 py-2 text-left text-gray-300 hover:bg-gray-700 rounded-md flex items-center space-x-3 transition-colors"
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/profile');
                  }}
                >
                  <User className="w-5 h-5 text-gray-400" />
                  <span>Profile</span>
                </motion.button>
                
                {user.currentSubscription?.isActive && (
                  <motion.button
                    whileHover={{ x: 5, backgroundColor: 'rgba(75, 85, 99, 0.5)' }}
                    className="w-full px-3 py-2 text-left text-gray-300 hover:bg-gray-700 rounded-md flex items-center space-x-3 transition-colors"
                    onClick={() => {
                      setIsOpen(false);
                      navigate('/dashboard');
                    }}
                  >
                    <Code className="w-5 h-5 text-gray-400" />
                    <span>Dashboard</span>
                  </motion.button>
                )}

                {user.admin && (
                  <motion.button
                    whileHover={{ x: 5, backgroundColor: 'rgba(79, 70, 229, 0.2)' }}
                    className="w-full px-3 py-2 text-left text-indigo-300 rounded-md flex items-center space-x-3 transition-colors border border-indigo-500/30 bg-indigo-500/10"
                    onClick={() => {
                      setIsOpen(false);
                      navigate('/admin');
                    }}
                  >
                    <Shield className="w-5 h-5 text-indigo-400" />
                    <span>Admin Portal</span>
                  </motion.button>
                )}
              </div>
              
              <div className="p-2 border-t border-gray-700">
                <motion.button
                  whileHover={{ x: 5, backgroundColor: 'rgba(239, 68, 68, 0.2)' }}
                  onClick={handleLogout}
                  className="w-full px-3 py-2 text-left text-red-400 hover:bg-gray-700 rounded-md flex items-center space-x-3 transition-colors"
                >
                  <LogOut className="w-5 h-5" />
                  <span>Logout</span>
                </motion.button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserMenu;