const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

export const API_ENDPOINTS = {
  ADMIN: {
    STATS: `${API_BASE_URL}/admin/stats`,
    LOGS: `${API_BASE_URL}/admin/logs`,
    LOGS_STATS: `${API_BASE_URL}/admin/logs/stats`,
    ACTIVITY: `${API_BASE_URL}/admin/activity`,
    HEALTH: `${API_BASE_URL}/admin/health`,
  },

  AUTH: {
    LOGIN: `${API_BASE_URL}/auth/discord/callback`,
    LOGOUT: `${API_BASE_URL}/auth/logout`,
    ME: `${API_BASE_URL}/auth/me`,
  },
  
  PAYMENT: {
    CREATE_CHECKOUT: `${API_BASE_URL}/create-checkout-session`
  },
  
  SECURITY: {
    CSRF_TOKEN: `${API_BASE_URL}/csrf-token`,
    RATE_LIMIT_CHECK: `${API_BASE_URL}/rate-limit-check`,
  },
  
  SERVICE: {
    STATS: `${API_BASE_URL}/service/stats`,
  },
  
  BOT: {
    SERVERS: (botId) => `${API_BASE_URL}/bot/servers/${botId}`,
  },
  
  FUNCTIONS: {
    CREATE_CHECKOUT: '/.netlify/functions/create-checkout'
  },
  
  WEBHOOK: {
    STRIPE: `${API_BASE_URL}/webhook`,
  }
};

export const isProduction = process.env.NODE_ENV === 'production';

export const apiConfig = {
  baseUrl: API_BASE_URL,
  version: process.env.REACT_APP_CLIENT_VERSION || '1.0.0',
  maxFileSize: 5 * 1024 * 1024,
  allowedFileTypes: ['image/jpeg', 'image/png', 'image/gif'],
  defaultHeaders: {
    'Content-Type': 'application/json',
  },
  requestTimeout: 30000,
};

export default API_ENDPOINTS;